import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { StyledButton } from "../../components/buttons";
import { CreditCard as CreditCardIcon } from '@phosphor-icons/react/dist/ssr/CreditCard';
import { CardHeader, Card, Avatar, Grid } from "@mui/material";
import { useTheme } from "../../themes/ThemeProvider";
import fetchData from "../../helpers/requests";
import { useAuth0 } from "@auth0/auth0-react";
import { Config } from "../../helpers/types";
import CustomizedSnackbars from "../../components/forms/UserFeedback/CustomizedSnackbars";

const CheckoutPage = (props: any) => {
  const { setOpenCheckoutPage, pricingTier } = props;
  const [clientSecret, setClientSecret] = useState('');
  const navigate = useNavigate()

  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("Success!")

  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
  const { currentTheme, config } = useTheme();
  const { getAccessTokenSilently } = useAuth0();


  useEffect(() => {
    fetchCheckoutSession(config, getAccessTokenSilently, setClientSecret, setAlertMessage, setShowAlert);
  }, []);

  return (
    <div id="checkout">
      <Card
        sx={{
          border: '1px solid rgba(0,0,0,0.1);',
          borderRadius: '10px',
          background: currentTheme.surface,
          color: currentTheme.onSurface,
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{
              bgcolor: currentTheme.primary,
              boxShadow: 'var(--mui-shadows-8)',
            }} >
              <CreditCardIcon fontSize="var(--Icon-fontSize)" />
            </Avatar>
          }
          title="Enter payment information"
        />
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
            <div
              style={{
                'padding': '10px 0px',
                'width': '100%',
                'textAlign': 'center',
              }}
            >
              <StyledButton
                enabled
                text={"Cancel"}
                onClick={() => setOpenCheckoutPage(false)}
              />
            </div>
          </EmbeddedCheckoutProvider>
        )}
      </Card>
      {showAlert ? <CustomizedSnackbars resetFunc={setShowAlert} severity={"error"} message={alertMessage} /> : null}
      {showAlert ? 
      <div style={{ 
        maxWidth: "fit-content",
        marginTop:"10px",
        marginLeft: "auto",
        marginRight: "auto"
        }}>

          <StyledButton
            enabled
            text={"Try Again"}
            onClick={() => navigate(0)}
          /> </div> : null}
    </div>
  )
}

export default CheckoutPage;

const fetchCheckoutSession = async (
  config: Config,
  getAccessTokenSilently: unknown,
  setClientSecret: Dispatch<SetStateAction<string>>,
  setAlertMessage: Dispatch<SetStateAction<string>>,
  setShowAlert: Dispatch<SetStateAction<boolean>>
) => {
  const token = await (getAccessTokenSilently as () => Promise<string>)();
  const result = await fetchData({
    url: import.meta.env.VITE_UNEVU_API_URL + "/checkout/session",
    method: "POST",
    domain: config?.name,
    token: token,
  })

  if (result.status !== 200) {
    setShowAlert(true);
    setAlertMessage(result.error + ". Please try again.");
  }
  // setEnableError(true);
  // setErrorMessage("Invalid domain name");
  setClientSecret(result.clientSecret)
}