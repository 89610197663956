import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from 'react-router-dom';
import { TopNavbar } from "../../components/navigation";
import { PageLoader } from "../../components/page-loader";
import fetchData from "../../helpers/requests";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { defaultConfig, useTheme } from "../../themes/ThemeProvider";
import { Config } from "../../helpers/types";
import { configsEqual } from "../../helpers/functions";
import CheckoutPage from "./CheckoutPage";
import { BillingDetails, BillingFooter, SubscriptionDetails } from "./components";
import { Box, Grid } from "@mui/material";
import { Snackbar } from "../../components/forms/UserFeedback";

const BillingPage = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const { config, setConfig } = useTheme();
    const [loadingConfig, setLoadingConfig] = useState(true);
    const [allowRedirect, setAllowRedirect] = useState(false);
    const [openCheckoutPage, setOpenCheckoutPage] = useState(false)
    const [nextDate, setNextDate] = useState("")
    const [triggerRefresh, setTriggerRefresh] = useState(false)
    const [pricingTier, setPricingTier] = useState("");
    const [showAlert, setShowAlert] = useState(false)

    if (isLoading) {
        return <PageLoader />
    }

    if (!isAuthenticated) {
        return <Navigate replace to="/login" />
    }

    useEffect(() => {
        getConfig(config, pricingTier, setConfig, setLoadingConfig, setNextDate, setPricingTier, getAccessTokenSilently);
        setNextDate(config?.subscription?.current_period_end)
        // if(config?.tier === "cancelled" || config?.tier === "cancel-pending" ) { setShowAlert(true) }
    }, [triggerRefresh]);

    useEffect(() => {
        if (!loadingConfig) {
            setAllowRedirect(true);
        }
    }, [loadingConfig]);

    if (allowRedirect && !config.connected) {  // OR USER AN ADMIN
        return <Navigate replace to="/configuration" />
    }

    return (
        <div>
            {config.connected ?
                <>
                    <TopNavbar menu={true} title={"Payment & Billing Information"} />

                    {!openCheckoutPage ? <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <BillingDetails nextDate={nextDate} pricingTier={pricingTier} />
                            </Grid>
                            <Grid item xs={6}>
                                <SubscriptionDetails pricingTier={pricingTier} setOpenCheckoutPage={setOpenCheckoutPage} triggerRefresh={triggerRefresh} setTriggerRefresh={setTriggerRefresh} setShowAlert={setShowAlert} />
                            </Grid>
                        </Grid>
                    </Box> : null}

                    {openCheckoutPage ?
                        <div style={{ width: '55vw', minWidth: '400px', maxWidth: '550px', margin: '0 auto', padding: '10px', paddingBottom: '60px' }}>
                            <CheckoutPage setOpenCheckoutPage={setOpenCheckoutPage} pricingTier={pricingTier} />
                        </div> : null}
                    <div style={{
                        'position': 'fixed',
                        'left': '0',
                        'bottom': '1%',
                        'width': '100%',
                        'textAlign': 'center',
                    }}>
                        <BillingFooter />
                    </div>
                </> : null
            }
            {showAlert ? <Snackbar resetFunc={setShowAlert} severity={"error"} message={"Subscription Cancelled, your data will continue to be available until the last day of your current billing period."} /> : null}

        </div>
    );
}

export default BillingPage;

const getConfig = async (
    config: Config,
    pricingTier: string,
    setConfig: Dispatch<SetStateAction<Config>>,
    setLoadingConfig: Dispatch<SetStateAction<boolean>>,
    setNextdate: Dispatch<SetStateAction<string>>,
    setPricingTier: Dispatch<SetStateAction<string>>,
    getAccessTokenSilently: unknown) => {
    const token = await (getAccessTokenSilently as () => Promise<string>)();

    // have user, give domain -> Make endpoint
    const userDomain = await fetchData({
        url: import.meta.env.VITE_UNEVU_API_URL + "/user", // /user?email='<string>' once endpoint is ready
        method: "GET",
        token: token
    })

    const domainConfig = await fetchData({
        url: import.meta.env.VITE_UNEVU_API_URL + "/domain/config", // /user?email='<string>' once endpoint is ready
        method: "GET",
        domain: userDomain?.data?.domains ? userDomain?.data?.domains[0] : null,
        token: token
    })

    const tier = await fetchData({
        url: import.meta.env.VITE_UNEVU_API_URL + "/tier?tier=0", // /user?email='<string>' once endpoint is ready
        method: "GET",
        token: token
    })
    setPricingTier(tier?.data?.name)

    if (domainConfig && domainConfig.data != null) {
        if (!configsEqual(config, domainConfig.data)) {
            setConfig(domainConfig.data);
            const stringifiedDomainConfig = JSON.stringify(domainConfig.data)
            localStorage.setItem("config", stringifiedDomainConfig)
        }

        if (config?.tier !== pricingTier) {
            const subscription = await fetchData({
                url: import.meta.env.VITE_UNEVU_API_URL + "/checkout/subscription", // /user?email='<string>' once endpoint is ready
                method: "GET",
                domain: userDomain?.data?.domains ? userDomain?.data?.domains[0] : null,
                token: token
            })

            setNextdate(subscription?.current_period_end)
        }
        // Perform additional processing here
    } else {
        setConfig(defaultConfig)
    }
    setLoadingConfig(false);
}
